
/* eslint-disable */
import BlogCard from '@/components/cards/BlogCard.vue'
import BlogFilter from 'tradingmate-modules/src/filters/BlogFilter'
import { BlogModel, BlogPostStatus } from 'tradingmate-modules/src/models/api/blogs'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import { Cards } from '@/components/cards'
import InfoState from '@/components/status/InfoState.vue'
import Paging from '@/components/actions/Paging.vue'

@Component({
  components: {
    BlogCard,
    Cards,
    InfoState,
    Paging
  }
})
export default class BlogIndex extends Vue {
  private blogData: PagedResultSet<BlogModel> | null = null
  gettingBlogs = false
  private hasErrors = false

  private filter: BlogFilter = new BlogFilter({
    BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId,
    Status: BlogPostStatus.None
  })

  mounted () {
    this.getBlogs()
  }

  getBlogs (): void {
    this.gettingBlogs = true
    this.hasErrors = false
    Services.API.Blogs.GetBlogsForConsole(this.filter)
    .then((blogs) => {
        this.blogData = blogs
    }).catch(() => {
      this.hasErrors = true
    }).finally(() => {
      this.gettingBlogs = false
    })
  }

  newBlogPost () {
    this.$router.push('/blog/new')
  }

  onPaged (page: number) {
    this.filter.Page = page;
    this.getBlogs();
  }
}
